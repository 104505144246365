import React from "react"
import styled, { css } from "styled-components"
import { media } from "../../styles/media"

//gatsby
import { Link, useStaticQuery, graphql } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

//component
import Layout from "../../components/Layout"
import ThirdLayerHeader from "../../components/ThirdLayerHeader"
import ThirdLayerClose from "../../components/ThirdLayerClose"
import { ThirdLayer, Main } from "../../components/ThirdLayerBody"
import SwipeMotionDiv from "components/ui/SwipeMotionDiv"
import CommentIllust from 'components/imageComps/CommentIllust'
import FoodIllust from 'components/imageComps/FoodIllust'
import SEO from '../../components/SEO'

const StyledEnd = styled.div`
  position: relative;
  p {
    width: 100%;
    font-size: 0.85em;
    text-align: center;
    line-height: 1.62em;
    padding-bottom: 32px;
  }
  .blue {
    width: 50px;
    position: absolute;
    left: 16px;
    bottom: 0;
    ${() => media.tablet(css`
      left:20%;
    `)}
  }
  .green {
    width: 50px;
    position: absolute;
    right: 16px;
    bottom: 0;
    ${() => media.tablet(css`
      right:20%;
    `)}
  }
`

const Supporter = props => {
  const data = useStaticQuery(query)
  const { location, transitionStatus, entry, exit } = props
  const parentPath = "/" + location.pathname.split("/")[1]

  const jsonld = [
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": `${data.seo.top.pass}`,
          "name": `${data.seo.top.title}`,
        }
      }, {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": `${data.seo.page.bitsKnow.pass}`,
          "name": `${data.seo.page.bitsKnow.title}`,
        }
      }, {
        "@type": "ListItem",
        "position": 3,
        "item": {
          "@id": `${data.seo.page.bitsKnow.supporter.pass}`,
          "name": `${data.seo.page.bitsKnow.supporter.title}`,
        }
      }]
    }
  ]

  return (
    <ThirdLayer>
      <Layout>
        <SEO
          title={data.seo.page.bitsKnow.supporter.title}
          pageUrl={data.seo.page.bitsKnow.supporter.pass}
          description={data.seo.page.bitsKnow.supporter.description}
          imgUrl='/mainVisual.jpg'
          type='article'
          jsonld={jsonld}
        />
        <SwipeMotionDiv
          entry={entry}
          exit={exit}
          transitionStatus={transitionStatus}
        >
          <ThirdLayerHeader
            text={data.text.supporter.leadText}
            parentPath={parentPath}
          />
          <Main>
            <CommentIllust
              title={data.text.supporter.waterSolubleBlock.blockTitle}
              text={data.text.supporter.waterSolubleBlock.paragraph1}
              comment={data.text.supporter.waterSolubleBlock.comment}
              charaImage={data.blue}
              comImage={data.blueCom}
              textColor='#A0C0E4'
            />
            <FoodIllust
              borderColor='#A0C0E4'
              text={data.text.supporter.waterSolubleBlock.innerBoxText}
              image={data.food1}
            />
            <CommentIllust
              title={data.text.supporter.insolubleBlock.blockTitle}
              text={data.text.supporter.insolubleBlock.paragraph1}
              comment={data.text.supporter.insolubleBlock.comment}
              charaImage={data.green}
              comImage={data.greenCom}
              textColor='#5BBDAE'
            />
            <FoodIllust
              borderColor='#5BBDAE'
              text={data.text.supporter.insolubleBlock.innerBoxText}
              image={data.food2}
            />
            <StyledEnd>
              <p>{data.text.supporter.insolubleBlock.paragraph2}</p>
              <StaticImage
                src="../../images/blueYoko.svg"
                alt="青のキャラ"
                placeholder="none"
                className="blue"
              />
              <StaticImage
                src="../../images/greenYoko.svg"
                alt="緑のキャラ"
                placeholder="none"
                className="green"
              />
            </StyledEnd>
          </Main>
          <ThirdLayerClose parentPath={parentPath} />
        </SwipeMotionDiv>
      </Layout>
    </ThirdLayer>
  )
}

export const query = graphql`
  {
    text: pagesYaml(pageName: { eq: "bitsOfKnowledgePage" }) {
      attention {
        leadText
        paragraph1
        paragraph2
        ref
        title
      }
      tendToLack {
        leadText
        paragraph1
        title
      }
      supporter {
        leadText
        title
        insolubleBlock {
          blockTitle
          comment
          innerBoxText
          paragraph1
          paragraph2
        }
        waterSolubleBlock {
          blockTitle
          comment
          innerBoxText
          paragraph1
        }
      }
    }
    blue: file(relativePath: {eq: "blue.png"}) {
      publicURL
      childImageSharp {
        gatsbyImageData(
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          )
      }
    }
    blueCom: file(relativePath: {eq: "blueCom.png"}) {
      publicURL
      childImageSharp {
        gatsbyImageData(
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          )
      }
    }
    green: file(relativePath: {eq: "green.png"}) {
      publicURL
      childImageSharp {
        gatsbyImageData(
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          )
      }
    }
    greenCom: file(relativePath: {eq: "greenCom.png"}) {
      publicURL
      childImageSharp {
        gatsbyImageData(
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          )
      }
    }
    food1: file(relativePath: {eq: "foodsBlue.png"}) {
      publicURL
      childImageSharp {
        gatsbyImageData(
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          )
      }
    }
    food2: file(relativePath: {eq: "foodsGreen.png"}) {
      publicURL
      childImageSharp {
        gatsbyImageData(
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          )
      }
    }
    seo: dataYaml {
      top {
        description
        pass
        title
      }
      page {
        bitsKnow {
          description
          pass
          title
          supporter {
            title
            pass
            description
          }
        }
      }
    }
  }
`

export default Supporter
