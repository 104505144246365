import React from 'react'
import styled, { css } from 'styled-components'
import { media } from '../../styles/media'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const StyledWrap = styled.div`
  margin:16px 16px 48px;
  border:1px solid;
  padding:20px;
  border-radius:8px;
  display:flex;
  flex-flow:column nowrap;
  align-items:center;
  justify-content:center;
  ${() => media.tablet(css`
    max-width:600px;
    margin:32px auto 48px;
  `)}
  .gatsby-image-wrapper{
    width:80%;
    margin-bottom:16px;
    ${() => media.tablet(css`
      width:60%;
    `)}
  }
  p{
    margin-bottom:0;
    font-size:.8em;
    line-height:1.1em;
    width:100%;
    padding:0;
  }
`

const FoodIllust = (props) => {
  return (
    <StyledWrap style={{ borderColor: props.borderColor }}>
      <GatsbyImage
        image={getImage(props.image)}
        alt='foods'
      />
      <p>
        {props.text}
      </p>
    </StyledWrap>
  )
}

export default FoodIllust
