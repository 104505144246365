import React from 'react'
import styled, { css } from 'styled-components'
import { media } from '../../styles/media'
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image'

const StyledDiv = styled.div`
  width:100%;
  padding:0 16px;
  h2{
    background:#292929;
    color:#A0C0E4;
    text-align:center;
    padding:.62em 0;
    border-radius:8px;
    font-weight:900;
    ${() => media.tablet(css`
      padding:1.12em 0;
    `)}
  }
  p{
    padding:0;
    margin:.62em 0 1.62em;
    ${() => media.tablet(css`
      margin:.62em 0 3.62em;
    `)}
  }
`
const StyledComment = styled.div`
  position:relative;
  padding:0 16px;
  display:flex;
  flex-flow:row nowrap;
  align-items:center;
  justify-content:center;
  height:150px;
      ${() => media.tablet(css`
      max-width:500px;
      margin:32px auto;
    `)}
  .chara{
    width:25%;
    margin-left:2%;
    position:absolute;
    left:0;
    bottom:30px;
  }
  .say{
    width:70%;
    position:absolute;
    right:0;
    top:50px;
    display:flex;
    flex-flow:row nowrap;
    align-items:center;
    justify-content:center;
    .comment{
      position:absolute;
    }
    .text{
      position:absolute;
      width:90%;
      margin-left:10%;
      text-align:center;
      font-weight:900;
    }
  }
`


const CommentIllust = (props) => {
  return (
    <StyledDiv>
      <h2 style={{ color: props.textColor }}>{props.title}</h2>
      <p>{props.text}</p>
      <StyledComment>
        <GatsbyImage
          image={getImage(props.charaImage)}
          alt='blue'
          className='chara'
        />
        <div className='say'>
          <GatsbyImage
            image={getImage(props.comImage)}
            alt='blueComment'
            className='comment'
          />
          <span className='text'>
            {props.comment}
          </span>
        </div>
      </StyledComment>
    </StyledDiv>
  )
}

export default CommentIllust
